.header {
  font-size: large;
  display: grid;
  background-color: rgb(71, 71, 150);
  grid-template-columns: 10% 10% 14% 14% 10%;
  justify-content: center;
  align-items: center;
  height: 4rem;
}

.headerlink-title {
  color: white;
  text-decoration: none;
  padding: 0 5px;
  margin-right: 2rem;
  font-size: large;
}
.headerlink-title:hover {
  text-decoration: none;
  color: wheat;
}

@media (max-width: 700px) {
  .header {
    font-size: large;
    display: grid;
    grid-template-columns: 15% 15% 20% 20% 20%;
    justify-content: center;
  }
  .headerlink-title {
    font-size: small;
    margin-right: 0rem;
  }
}

