.overskrift {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.post-image {
    max-width: 70%;
    max-height: 70%;

}
