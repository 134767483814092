.post-image {
    max-width: 70%;
    max-height: 70%;

}



.post {
    margin-bottom: 1rem;
}

.tittel {
    margin-bottom: 3rem;
}

.publiseringsdato {
    opacity: 50%;
    margin-top: 1rem;
    margin-left: 1rem;
}