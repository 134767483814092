.knazen-bygg {
  width: 70%;
  margin-bottom: 1rem;
}

.lonena {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.banner {
  position: relative;
  margin: 0 auto 0 auto;
  max-height: 280px;
  overflow: hidden;
  min-height: 135px;
}

.banner img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.banner__overlay h1 {
  padding: 25px 40px;
  margin: 0 auto;
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.banner__overlay h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
}
.banner__overlay {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

@media (max-width: 700px) {
  .banner__overlay h1 {
    padding: 15px 30px;
    font-size: 30px;
  }
}

.banner__overlay h1 .highLight {
  font-weight: 300;
}

.footer-content {
  display: grid;
  background-color: #25253d;
  grid-template-columns: 33% 33% 33%;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 3rem;
  color: white;
  justify-content: center;
  overflow-y: hidden;
}

.router {
  max-width: 100%;
}

.footer-element {
  display: flex;
  flex-direction: column;
}

.footer-link {
  text-decoration: none;
  color: white;
}

.footer-link:hover {
  text-decoration: none;
  color: wheat;
}

.map-img {
  height: 60%;
}

@media (max-width: 750px) {
  .footer-content {
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 100%;
    width: 100%;
    justify-content: center;
  }
  .footer-element {
    justify-content: space-around;
  }
}
